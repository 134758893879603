import { SubText } from "../../models/LabelSubtext";
import { Label } from "../../models/label";
import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { scrollToComponent } from "../../utils/effects";
import appAssets from "../../config/registry";

export const NavContactDesktop = () => {
  useEffect(() => {}, []);
  return (
    <Stack
      className="nav-btns header"
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      sx={{ width: "100%" }}
      gap={2}
    >
      <Stack
        gap={0.5}
        className="mouse_interaction-target"
        direction="row"
        alignItems="center"
        onClick={() => {
          scrollToComponent("experience");
        }}
      >
        <SubText>01.</SubText>

        <Label className="desktop-nav-label">Experience</Label>
      </Stack>
      <Stack
        gap={0.5}
        className="mouse_interaction-target"
        direction="row"
        alignItems="center"
        onClick={() => {
          scrollToComponent("works");
        }}
      >
        <SubText>02.</SubText>
        <Label className="desktop-nav-label">Works</Label>
      </Stack>
      <Stack
        gap={0.5}
        className="mouse_interaction-target"
        direction="row"
        alignItems="center"
        onClick={() => {
          scrollToComponent("contactme");
        }}
      >
        <SubText>03.</SubText>
        <Label className="desktop-nav-label">Contact me</Label>
      </Stack>
      <Box
        className="d_resumespan mouse_interaction-target"
        sx={{ border: "2px solid rgb(20, 240, 185)" }}
      >
        <a href={appAssets.resume} target="_blank" rel="noopener noreferrer">
          <Typography
            variant="subtitle2"
            sx={(theme) => ({
              color: theme.palette.text.primary
            })}
          >
            Resume
          </Typography>
        </a>
      </Box>
    </Stack>
  );
};
