import { Box, Stack, Theme } from "@mui/material";
import React from "react";

import "./profile.css";
import { aboutMe } from "../../data/me";
import { grey } from "@mui/material/colors";
export const MobileProfile = () => {
  return (
    <Stack gap={3} className="m-profile-picture" sx={styles.container}>
      <Box
        data-aos="fade-up"
        className="m-footer_circle"
        sx={(theme) => ({
          backgroundColor:
            theme.palette.mode === "dark" ? "#01916e" : theme.palette.grey[400]
        })}
      >
        <Box className="m-line-box"></Box>
      </Box>
      <Box className="m-profile-container">
        <Box
          className="profile-image-container"
          sx={(theme) => ({
            boxShadow:
              theme.palette.mode === "dark"
                ? "transparent"
                : " rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;"
          })}
        >
          <img
            src={aboutMe.photo}
            className="profile-image"
            alt=""
            style={{
              width: "100%",
              backgroundColor: grey[900],
              height: "100%"
            }}
          />
        </Box>

        <Box className="profile-frame"></Box>
      </Box>
    </Stack>
  );
};

const styles = {
  container: (theme: Theme) => ({
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  })
};
