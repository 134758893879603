import { Box, Stack, Typography } from "@mui/material";
import "./contact.css";
import { useResponsive } from "../../hooks/use-responsive";
import { BsAt, BsChatDots, BsHandIndexThumb } from "react-icons/bs";
import { contacts } from "../../data";
import { HoverIcon } from "../../models/HoverIcon";
import appAssets from "../../config/registry";
import { aboutMe } from "../../data/me";

const Contact = () => {
  const upLg = useResponsive("up", "lg");

  return (
    <Box
      id="contactme"
      className="contactme"
      style={{ height: `${window.innerHeight}px` }}
    >
      <Stack
        gap={upLg ? 3 : 2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="footermain"
      >
        <Box className="footer_lines">
          <Box
            className="footer_line_l  targets-container-width"
            sx={(theme) => ({
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "#05af85"
                  : theme.palette.grey[500],
              height: 2,
              width: upLg ? "47%" : "42%"
            })}
          ></Box>
          <Box
            data-aos="fade-up"
            className="footer_circle targets-container"
            sx={(theme) => ({
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "#01916e"
                  : theme.palette.grey[400]
            })}
          >
            <Box className="line-box"></Box>
          </Box>
          <Box
            className="footer_line_r  targets-container-width"
            sx={(theme) => ({
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "#05af85"
                  : theme.palette.grey[500],
              height: 2,
              width: upLg ? "47%" : "42%"
            })}
          ></Box>
        </Box>
        <Stack
          direction="column"
          gap={upLg ? 5 : 3}
          alignItems="center"
          justifyContent="center"
          className="footer_text "
        >
          <Typography
            variant={upLg ? "h2" : "h3"}
            sx={(theme) => ({
              color:
                theme.palette.mode === "dark"
                  ? "#05af85"
                  : theme.palette.grey[700]
            })}
            className="projectM"
          >
            Lets connect
          </Typography>
          <Typography
            variant={upLg ? "h4" : "subtitle2"}
            sx={(theme) => ({
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[700]
            })}
            className="projectM "
          >
            I am presently available to collaborate
            with novel developers, while remaining open to learning and adapting
            to new technologies and challenges.
          </Typography>

          <Box
            sx={(theme) => ({
              border:
                theme.palette.mode === "dark"
                  ? "2px solid rgb(20, 240, 185)"
                  : "2px solid #454F5B",
              color:
                theme.palette.mode === "dark"
                  ? "#018c6a"
                  : theme.palette.grey[700]
            })}
            className="d_resumespan mouse_interaction-target"
          >
            <a
              href={appAssets.resume}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                variant="subtitle2"
                sx={(theme) => ({
                  color: theme.palette.text.primary
                })}
              >
                Resume
              </Typography>
            </a>
          </Box>
        </Stack>
        <Stack
          sx={{
            marginTop: "40px"
          }}
          direction="row"
          gap={upLg ? 2 : 0}
          alignItems="center"
          className="l_footer_mail targets-container"
        >
          {" "}
          <span className="d_hand">
            <Box className="handIndexThumb_footer">
              <BsHandIndexThumb size={25} />
            </Box>
          </span>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            onClick={() => {
              window.location.href = `mailto:${aboutMe.gmail}`;
            }}
            className="d_footer_click mouse_interaction-target"
          >
            <BsChatDots size={25} />

            <Typography>Chat me</Typography>
          </Stack>
        </Stack>
        {!upLg && (
          <Stack
            direction="row"
            gap={3}
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: "40px"
            }}
          >
            {contacts &&
              contacts.map((data, id) => {
                return (
                  <Box key={id}>
                    <HoverIcon
                      icon={data.icon}
                      label={data.name}
                      link={data.link}
                    />
                  </Box>
                );
              })}
          </Stack>
        )}
      </Stack>

      <Stack direction="row" className="footer-author ">
        <Typography
          sx={(theme) => ({
            color:
              theme.palette.mode === "dark"
                ? "#05af85"
                : theme.palette.grey[700],
            fontWeight: "bold"
          })}
          className="footer_create"
        >
          Handcrafted by James Mensah
        </Typography>
        <BsAt />
        <Typography
          sx={(theme) => ({
            color:
              theme.palette.mode === "dark"
                ? "#05af85"
                : theme.palette.grey[700],
            fontWeight: "bold"
          })}
        >
          2024
        </Typography>
      </Stack>
    </Box>
  );
};

export default Contact;
