import { ProjectProps } from "../utils/types";

type skillType = "language" | "devtools" | "frameworks" | "os";

interface skillProps {
  type: skillType;
  name: string;
  image?: string;
  dark?: string;
  light?: string;
}
interface experienceProps {
  title: {
    image?: string;
    text: string;
  };
  date: string;
  detail: string;
  skills?: skillProps[];
}

export const PageIamges = {
  backgroundAbsolute: "assets/images/shape.svg"
};

export const aboutMe = {
  photo: "assets/images/james-mensah.jpg",
  number: "+233 557471572",
  gmail: "jamesmensahcoded@gmail.com",
  whatsapp: "+233 557471572",
  telegram: "+233 557471572",
  github: "https://github.com/james-mensa"
};

export const mySelf: string = `I am a full stack developer specializing in web and mobile development, dedicated to creating user-centric and innovative digital solutions. With an interest in Web3 dApps and blockchain technologies, I am passionate about continuous learning and evolving with the dynamic tech industry.`;

export const experienceData: experienceProps[] = [
  {
    title: {
      text: "Software developer at zk-block",
      image: "assets/images/zkrypto.png"
    },
    date: "2023-PRESENT",

    skills: [
      { type: "language", name: "Java", dark: "#17171f", light: "#ebecf5" },
      {
        type: "language",
        name: "TypeScript",
        dark: "#17171f",
        light: "#ebecf5"
      },
      { type: "language", name: "Solidity", dark: "#17171f", light: "#ebecf5" },

      {
        type: "frameworks",
        name: "React.js",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "Next.js",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "Node.js",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "Nest.js",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "React Native",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "PostgreSQL",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "MongoDB",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "RealmDB",
        dark: "#17171f",
        light: "#ebecf5"
      },
      { type: "devtools", name: "Docker", dark: "#17171f", light: "#ebecf5" },
      {
        type: "devtools",
        name: "Kubernetes",
        dark: "#17171f",
        light: "#ebecf5"
      },
      { type: "devtools", name: "Grafana", dark: "#17171f", light: "#ebecf5" },
      {
        type: "devtools",
        name: "Prometheus",
        dark: "#17171f",
        light: "#ebecf5"
      },
      { type: "devtools", name: "DevOps", dark: "#17171f", light: "#ebecf5" },

      {
        type: "frameworks",
        name: "Ethereum",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "Blockchain",
        dark: "#17171f",
        light: "#ebecf5"
      },
      { type: "os", name: "Linux(Ubuntu)", dark: "#17171f", light: "#ebecf5" }
    ],

    detail: `I am actively involved in developing blockchain solutions at Zkblock, including a block explorer web application utilizing React.js, Next.js, Ether.js, and Web3 technologies to monitor Ethereum transactions. I also focus on smart contract development with efficient deployments using Docker and Bash scripting and contribute to cross-platform mobile app development across projects.`
  },

  {
    title: {
      text: "Full Stack developer at badu devapps",
      image: "assets/images/baduapp.png"
    },
    date: "2021-2023",
    detail: `Built client applications using React.js for front-end development, Tailwind CSS for styling, and Webpack for asset management. On the backend, I used Node.js, MongoDB, PostgreSQL, and AWS (Route 53, S3). I also contributed to Flutter-based mobile app development for cross-platform solutions.`,

    skills: [
      {
        type: "language",
        name: "Java",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "language",
        name: "Javascript",
        dark: "#17171f",
        light: "#ebecf5"
      },

      {
        type: "language",
        name: "Python",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "language",
        name: "Dart",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "React js",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "Node js",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "React Native",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "Flutter",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "Sqlite",
        dark: "#17171f",
        light: "#ebecf5"
      },

      {
        type: "frameworks",
        name: "Mysql",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "Java swing",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "Spring Boot",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "MongoDB",
        dark: "#17171f",
        light: "#ebecf5"
      },

      {
        type: "devtools",
        name: "Aws",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "devtools",
        name: "Nextlify",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "os",
        name: "Mac OS ",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "os",
        name: "Windows ",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "devtools",
        name: "Github",
        dark: "#17171f",
        light: "#ebecf5"
      }
    ]
  },
  {
    title: {
      text: "Freelancer at Upwork",
      image: "assets/images/upwork.png"
    },
    date: "2019-2022",
    detail: `I have freelance experience using WordPress, Shopify, HTML, and JavaScript to create and customize websites for various clients and colleagues during my time as a student pursuing my BSc in Computer Science at the University of Ghana, Legon`,

    skills: [
      {
        type: "language",
        name: "Java",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "language",
        name: "Javascript",
        dark: "#17171f",
        light: "#ebecf5"
      },

      {
        type: "frameworks",
        name: "Html",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "frameworks",
        name: "CSS",
        dark: "#17171f",
        light: "#ebecf5"
      },

      {
        type: "frameworks",
        name: "Mysql",
        dark: "#17171f",
        light: "#ebecf5"
      },

      {
        type: "devtools",
        name: "Wordpress",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "devtools",
        name: "Shopify",
        dark: "#17171f",
        light: "#ebecf5"
      },
      {
        type: "devtools",
        name: "Github"
      },
      {
        type: "os",
        name: "windows ",
        dark: "#17171f",
        light: "#ebecf5"
      }
    ]
  }
];

export const projectsList: ProjectProps[] = [
  {
    title: "Fit360",
    detail: `Fit360 is more than just a fitness app—it's your comprehensive guide to achieving and maintaining your health and wellness goals. Whether you're a seasoned athlete or just starting your fitness journey, Fit360 provides 
        personalized workout plans, detailed progress tracking, and expert nutritional advice to help you succeed.`,
    tools: ["Typecript", "Node js", "react native", "Realm db"],
    isVideo: true,
    image: "assets/videos/fit360_demo.mp4",
    github: "https://github.com/james-mensa/fit360"
  },
  {
    title: "Faucet App @ZKBLOCK",
    detail:
      "The beta-test-faucet allows users to mint tokens, including NFTs, ERC-20, ERC-1155, and native tokens across multiple testnet networks such as Baobab, Sepolia, and Ripple EVM.",
    //link: "https://beta-test-faucet.zkrypto.com/",
    tools: ["web3.js", "TypeScript", "Nest.js", "Next.js", "MongoDB"],
    image: "assets/images/zkblock_faucet.png"
  },
  {
    title: "Badu Devapps",
    detail: `Collaborated on the design and development of enterprise-level applications focusing on performance and scalability.Played a key role in building and maintaining BadudevApps' officia
l website, leveraging modern web technologies to enhance user experience and functionality`,
    link: "https://www.badudevapps.com/",
    tools: ["Typecript", "Node js", "Next js", "Aws"],
    image: "assets/images/badudevs.png"
  },
  {
    title: "About me",
    detail: `This platform is designed to showcase my professional journey and highlight my expertise in full-stack development, web and mobile development, and Web3 technologies.
    It serves as a comprehensive display of my skills, experiences, and works, including both professional and personal projects`,
    link: "https://jamesmensahme.com/",
    tools: ["Javascript", "Node js", "Next js", "Aws"],
    image: "assets/images/resume.png",
    headerIcon: (
      <img
        src={"assets/images/lol.gif"}
        alt="icon"
        style={{ height: "20px", width: "20px", borderRadius: "50px" }}
      />
    )
  },
  {
    title: "MY IDRescue",
    detail: `Find, Post, Reunite with Real Owner - Let's Save the Stress .
               Welcome to IDRescue, the ultimate solution for lost ID card recovery! Have you lost your ID card? Or perhaps you've found one? We're here to help you reunite with your lost ID card or connect with its rightful owner`,
    link: "https://myidrescue.netlify.app/",
    tools: ["Javascript", "Node js", "React js", "MongoDB", "Nextlify"],
    image: "assets/images/myidfinder.png"
  },

  {
    title: "DSA Inventory Desktop App",
    detail:
      "A cool desktop app where algorithms do the heavy lifting! Manage your inventory with ease, search lightning-fast, and keep things organized—all wrapped up in a sleek Java Swing interface with SQLite magic.",
    github: "https://github.com/james-mensa/Inventory-Management-System",
    link: "https://github.com/james-mensa/Inventory-Management-System/releases/download/v0.01/MyShopkeeper.exe",
    tools: ["Java", "Java Swing", "SQLite"],
    image: "assets/images/java-swing.png"
  },

  {
    title: "Crestview lodge",
    detail: `At crestview, we pride ourselves on delivering impeccable service that goes beyond your expectations. Whether you're here for business or leisure, we aim to make your stay with us truly exceptional.Nestled in the heart of Greater Accra, our boutique hotel offers a perfect blend of modern sophistication and warm hospitality`,
    link: "https://rixoscomfort.netlify.app/",
    tools: ["Javascript", "Node.js", "Next.js", "Aws"],
    image: "assets/images/rixos.png"
  },

  {
    title: "Myshareexclusive",
    detail: `Learn from Experts from the comfort of your home.The beginning of a new age .The journey to full Industrization and Digitalization
             Be incharge ,Be your own BOSS`,
    github: "https://github.com/james-mensa/myshare",
    tools: ["Javascript", "Node.js", "AWS", "React.js", "MongoDB", "NameCheap"],
    image: "assets/images/myshare.png"
  }
];
